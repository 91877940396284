<template >
    <div>
        <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
                        {{
                            $t("message.edit_m", {
                                m: $t("message.tariff_category"), 
                            })
                        }}
                    </p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0">
              <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
                <el-form
                      ref="form"
                      status-icon
                      :model="form"
                      :rules="rules"
                      >
                    <el-row :gutter="20">
                     
                      <el-col :span="24">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                          {{ $t("message.branch") }}
                        </span>
                        <el-form-item prop="branch_id">
                          <select-branch
                            :company_id="form.company_id"
                            :id="form.branch_id"
                            :size="'medium'"
                            v-model="form.branch_id"
                            @input="changeElamant"
                          >
                          </select-branch>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item
                          :class="mode ? 'input__day' : 'input__night'"
                            :label="$t('message.coefficient')"
                            prop="coefficient"
                            class="label_mini"  >
                            <crm-input
                                :size="'medium'"
                                :type="'number'"
                                :inputValue="form.coefficient"
                                v-model="form.coefficient"
                                :placeholder="$t('message.coefficient')"
                            ></crm-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24" v-if="form.coefficient&&minimum_wage">
                        <h4 class="m-0">{{$t('message.wage')}}: {{  form.coefficient?(minimum_wage.wage*form.coefficient).toLocaleString("fi-FI"):'' }}</h4>
                      </el-col>
                      <!-- end col -->
                    </el-row>
                </el-form>
              </div>
          </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
/**
 * Drawer chaqirish kerak drawerni yopish uchun
 */
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
export default {
    mixins: [form, drawer, show],
    name: "NationController",
    data() {
        return {};
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "tariffCategory/rules",
            minimum_wage: "tariffCategory/minimum_wage",
            model: "tariffCategory/model",
            columns: "tariffCategory/columns",
            mode:"MODE"
        }),
    },
    methods: {
        ...mapActions({
            update: "tariffCategory/update",
            show: "tariffCategory/show",
            getMinimumWage: "tariffCategory/getMinimumWage",

        }),
        changeElamant(){
          if (this.form.branch_id) {
            this.getMinimumWage(this.form.branch_id);            
          }
        },
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>
